.tablecontainer {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.tablecontainer table {
    border: 1px solid black;
    padding: 10px;
}
.tablecontainer table th {
    padding: 10px;
}

.tablecontainer table td {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding: 10px;
}
.tablecontainer .inputbox {
    border: 2px solid rgb(247, 170, 29);
    padding: 5px;
    border-radius: 10px;
    width: 50px;
}
.tablecontainer .replacementbutton{
    width: 100px;
    font-size: 1em;
    letter-spacing: .5px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #1F80E0;
    color: white;
    &:hover {
        background-color: rgb(242, 168, 72);
        color: black;
    }
}

.tablecontainer .pendingbutton{
    width: 100px;
    font-size: 1em;
    letter-spacing: .5px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #fe5631;
    color: white;
}