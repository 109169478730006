.thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 60vh;
}

.thanksyoudetails-container-A{
    color: white;
    margin: 1%;
    width: 48%;
    text-align: center;
}
.thanksyoudetails-container-A p{
    font-size: 3.5em;
    font-weight: 400;
    margin-top: 15%;
}
.thanksyoudetails-container-B{
    margin: 1%;
    width: 48%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    background-color: #F8F9FA;
    margin-top: 4%;
    height: 40vh;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.thanksyoudetails-container-B p{
    color: #686868;
    margin-left: 12%;
    margin-top: 15%;
    font-size: 2em;
}

@media only screen and (min-width: 901px) and (max-width: 1001px) {
    .thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 75vh;
}
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3.5em;
        font-weight: 400;
        margin-top: 8%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        margin: 2%;
        width: 95%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        margin-bottom: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-left: 10%;
        margin-top: 10%;
        font-size: 2.2em;
    }
}

@media only screen and (min-width: 801px) and (max-width: 901px) {
    .thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 75vh;
}
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3.4em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        margin: 2%;
        width: 95%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        margin-bottom: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 15%;
        font-size: 2.2em;
    }
}

@media only screen and (min-width: 701px) and (max-width: 801px) {
    .thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 70vh;
}
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3.4em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        margin: 2%;
        width: 95%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        margin-bottom: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 15%;
        font-size: 2.2em;
    }
}
@media only screen and (min-width: 601px) and (max-width: 701px) {
    .thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 70vh;
}
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3.2em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        margin: 2%;
        width: 95%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        margin-bottom: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 20%;
        font-size: 2em;
    }
}
@media only screen and (min-width: 501px) and (max-width: 601px) {
    .thankyoupodetails-container{
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: 70vh;
}
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        margin: 2%;
        width: 95%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        margin-bottom: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 25%;
        font-size: 1.8em;
    }
}
@media only screen and (min-width: 401px) and (max-width: 501px) {
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        width: 90%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 30%;
        font-size: 1.5em;
    }
}
@media only screen and (min-width: 300px) and (max-width: 401px) {
    .thanksyoudetails-container-A{
        color: white;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .thanksyoudetails-container-A p{
        font-size: 3em;
        font-weight: 400;
        margin-top: 15%;
    }
    .thanksyoudetails-container-B{
        margin-left: 5%;
        width: 90%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        margin-top: 4%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .thanksyoudetails-container-B p{
        color: #686868;
        margin: 5%;
        margin-top: 35%;
        font-size: 1.5em;
    }
}