.Membership-div1{
    margin-left: 2%;
    margin-top: 5%;
}
.button-container{
    text-align: center;
    margin-top: 2%;
}
.ptagwithcolor{
    font-size: 1.4em;
    font-weight: 700;
    color: #1F80E0;
}
.ptagwithoutcolor{
    font-size: 1.2em;
    font-weight: 700;
}
.primeFeaturesList{
    margin-left: 3%;
    margin-top: 10px;
}

.button-container .buttonDesign{
    background-color: #1F80E0;
    font-size: 1.8em;
    color: white;
    font-weight: 600;
    padding: 15px;
    border-radius: 5px;
    border: none;
}
@media only screen and (min-width: 1001px) and (max-width: 1101px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 100%;
    }
    .ptagwithcolor{
        font-size: 1.4em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        font-size: 1.5em;
        width: 700px;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 901px) and (max-width: 1001px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 100%;
    }
    .ptagwithcolor{
        font-size: 1.3em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        font-size: 1.4em;
        width: 700px;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 801px) and (max-width: 901px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.3em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        width: 600px;
        font-size: 1.3em;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 701px) and (max-width: 801px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
        width: 550px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        width: 600px;
        font-size: 1.4em;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 601px) and (max-width: 701px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
        width: 450px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        width: 550px;
        font-size: 1.35em;
        color: white;
        font-weight: 600;
        padding: 12px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 501px) and (max-width: 601px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        margin-left: 3%;
        margin-top: 10px;
        width: 350px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        width: 90vw;
        font-size: 1.4em;
        color: white;
        font-weight: 600;
        padding: 10px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 401px) and (max-width: 501px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        width: 300px;
        font-size: 1.2em;
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 15%;
    }
    .button-container .buttonDesign{
        width: 85vw;
        background-color: #1F80E0;
        font-size: 1.5em;
        color: white;
        font-weight: 600;
        padding: 12px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 301px) and (max-width: 401px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 700;
        color: #1F80E0;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 700;
    }
    .primeFeaturesList{
        width: 200px;
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 5%;
    }
    .button-container .buttonDesign{
        width: 80vw;
        margin-top: 25%;
        background-color: #1F80E0;
        font-size: 1.2em;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}
@media only screen and (min-width: 201px) and (max-width: 301px) {
    .Membership-div1{
        margin-left: 2%;
        margin-top: 5%;
        width: 90%;
    }
    .ptagwithcolor{
        font-size: 1.2em;
        font-weight: 600;
        color: #1F80E0;
        width: 90%;
    }
    .ptagwithoutcolor{
        font-size: 1em;
        font-weight: 600;
    }
    .primeFeaturesList{
        width: 150px;
        margin-left: 3%;
        margin-top: 10px;
    }
    .button-container{
        text-align: center;
        margin-top: 25%;
    }
    .button-container .buttonDesign{
        background-color: #1F80E0;
        width: 70vw;
        font-size: 1.2em;
        color: white;
        font-weight: 600;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }   
}