.stepTwo-main-container {
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
    height: auto;
}
.stepTwo-div1-container{
    width: 35%;
    text-align: center;
    margin-bottom: 2%;
}
.stepTwo-div1-header p {
    font-size: 2.5em;
    color: white;
    margin-top: 40%;
    margin-left: 20%;
}
.stepTwo-div1-backButton-div{
    margin-top: 5%;
    margin-bottom: 5%;
}
.stepTwo-div1-button-prev{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 20%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 55%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 7%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 5%;
    margin-bottom: 2%;
    width: 100%;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 4%;
}
.stepTwo-div2-select {
    width: 25em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 2%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-checkbox-container{
    /* display: inline-block; */
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.pr-checkbox-div{
    margin-left: 18%;
    margin-top: 1%;
    width: 30%;
}
.pr-checkbox-inputbox{
    margin-right: 10px;
}
.propertyRequirement-checkbox-label{
    padding: 5px;
    margin-top: 10px;
    font-size: 1.2em;
    letter-spacing: 0.5px;
}
.stepTwo-Next-button-container{
    width: 100%;
    margin-top: 3%;
    margin-bottom: 2%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}


@media only screen and (min-width: 901px) and (max-width: 1001px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.8em;
    color: white;
    margin-top: 6%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 6em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.6em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 0%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 5%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 2%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 5%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 5%;
    margin-bottom: 3%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 801px) and (max-width: 901px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.8em;
    color: white;
    margin-top: 5%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 6%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 3%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.8em;
    font-weight: 500;
    color: #686868;
    margin-top: 6%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 5%;
    margin-bottom: 2%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 701px) and (max-width: 801px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.8em;
    color: white;
    margin-top: 5%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.6em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.6em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 4%;
    margin-bottom: 2%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 601px) and (max-width: 701px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.5em;
    color: white;
    margin-top: 5%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.5em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.5em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 5%;
    margin-bottom: 3%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 501px) and (max-width: 601px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.5em;
    color: white;
    margin-top: 5%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.5em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 80%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.5em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 6%;
    margin-bottom: 3%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 401px) and (max-width: 501px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.3em;
    color: white;
    margin-top: 10%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.4em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 80%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 301px) and (max-width: 401px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2.1em;
    color: white;
    margin-top: 15%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.4em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 15%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 80%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}

@media only screen and (min-width: 201px) and (max-width: 301px) {
.stepTwo-div1-container{
    width: 100%;
}
.stepTwo-div1-header p {
    font-size: 2em;
    color: white;
    margin-top: 15%;
    margin-left: 2%;
}
.stepTwo-div1-button-prev{
    width: 5em;
    height: 1.8em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.3em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
    margin-left: 3%;
}
.stepTwo-div2-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    text-align: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepTwo-div2-subheading-style{
    font-size: 1.3em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 20%;
    width: 70%;
}
.stepTwo-div2-select {
    width: 80%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 5%;
}
.stepTwo-div2-ri-checkboxes{
    width: 100%;
    text-align: center;
}
.stepTwo-div2-subheading-down-style{
    font-size: 1.3em;
    font-weight: 500;
    color: #686868;
    margin-top: 10%;
    margin-left: 10%;
}
.stepTwo-div2-checkbox-container{
    text-align: left;
    margin-top: 2%;
}
.pr-checkbox-div{
    width: 100%;
}
.stepTwo-Next-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
.stepTwo-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepTwo-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
}