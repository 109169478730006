.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #848484;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .search-input {
    border: none;
    padding: 10px;
    flex: 1;
    outline: none;
    font-size: 1.2em;
    height: 40px;
  }
  
  .search-button {
    background-color: #f8f8f8;
    border: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-button:hover {
    background-color: #e0e0e0;
  }
  