.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
    height: auto;
}
.div-checkbox-continer{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    background-color: white;
    width: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.div-checkbox-continer-div{
   margin-top: 1%;
   margin-left: 4%; 
   width: 45%;
}
.prs-checkbox-label{
    font-size: 1.2em;
    letter-spacing: 0.5px;
}
.prs-checkbox-inputbox{
    margin-right: 10px;
}
.prs-button-container{
    margin-top: 4%;
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}
.propertyrequirements-container-header-div{
    width: 35%;
    text-align: center;
    margin-top: 15%;
}
.propertyrequirements-container-header{
    font-size: 2.5em;
    text-align: center;
    color: #F8F9FA;
}
.propertyRequirements-backbutton{
    margin-top: 10%;
}
.div-checkbox-continer-header-div{
    margin-top: 2%;
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}
.div-checkbox-continer-header{
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
    width: 100%;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}
.propertyRequirements-button-prev{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
}
.propertyRequirements-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.propertyRequirements-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
@media only screen and (min-width: 1301px) and (max-width: 1401px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 40%;
}
.div-checkbox-continer{
    width: 55%;
    /* margin-top: 10%; */
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 2%;
}
.div-checkbox-continer-header{
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 1.8em;
    width: 100%;
    text-align: center;
}
.div-checkbox-continer-div{
    width: 40%;
}
}
@media only screen and (min-width: 1401px) and (max-width: 1501px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 40%;
}
.div-checkbox-continer{
    width: 58%;
    /* margin-top: 10%; */
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    /* margin-left: 5%; */
}
.div-checkbox-continer-header{
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 1.8em;
    width: 100%;
    text-align: center;
}
.div-checkbox-continer-div{
    width: 40%;
}
}
@media only screen and (min-width: 1201px) and (max-width: 1301px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 40%;
}
.div-checkbox-continer{
    width: 55%;
    /* margin-top: 10%; */
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    /* margin-left: 5%; */
}
.div-checkbox-continer-header{
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 1.8em;
    width: 100%;
    text-align: center;
}
.div-checkbox-continer-div{
    width: 40%;
}
}
@media only screen and (min-width: 1101px) and (max-width: 1201px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 1.8em;
    width: 100%;
    text-align: center;
}
.div-checkbox-continer-div{
    width: 40%;
}
.propertyRequirements-backbutton{
    margin-top: 5%;
}
}
@media only screen and (min-width: 1001px) and (max-width: 1101px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 6%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 6%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 6%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.propertyRequirements-backbutton{
    margin-top: 5%;
}
}
@media only screen and (min-width: 901px) and (max-width: 1001px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 6%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 6%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 6%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.propertyRequirements-backbutton{
    margin-top: 5%;
}
}
@media only screen and (min-width: 801px) and (max-width: 901px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 8%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.propertyRequirements-backbutton{
    margin-top: 6%;
}
}
@media only screen and (min-width: 701px) and (max-width: 801px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 8%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.propertyRequirements-backbutton{
    margin-top: 6%;
}
}
@media only screen and (min-width: 601px) and (max-width: 701px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
    margin-top: 10%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.propertyRequirements-backbutton{
    margin-top: 8%;
}
}
@media only screen and (min-width: 501px) and (max-width: 601px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
    width: 100%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 1.8em;
}
.div-checkbox-continer-div{
    width: 80%;
}
.prs-button-container{
    margin-top: 5%;
    margin-bottom: 3%;
}
}
@media only screen and (min-width: 401px) and (max-width: 501px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
width: 100%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
    margin-bottom: 5%;
}
.div-checkbox-continer-div{
    width: 80%;
}
.prs-button-container{
    margin-top: 5%;
    margin-bottom: 3%;
}
}
@media only screen and (min-width: 301px) and (max-width: 401px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
width: 100%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
}
.div-checkbox-continer-div{
    width: 80%;
}
.prs-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
}
@media only screen and (min-width: 201px) and (max-width: 301px) {
.propertyrequirements-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
}
.propertyrequirements-container-header-div{
width: 100%;
}
.div-checkbox-continer{
    width: 90%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    margin-left: 5%;
}
.div-checkbox-continer-header{
    margin-top: 10%;
}
.div-checkbox-continer-div{
    width: 80%;
}
.prs-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
}