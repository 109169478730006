* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.pricing-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.pricing-container .header .title {
  font-size: 35px;
  text-align: center;
}
.pricing-container .header .description {
  font-size: 17px;
  text-align: center;
  margin-top: 10px;
  width: 700px;
}
.pricing-container .pricing-plans {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: center;
  margin-top: 50px;
}
.pricing-container .pricing-plans ::-webkit-scrollbar {
  width: 5px;
  background-color: lightgray;
  border-radius: 10px;
}
.pricing-container .pricing-plans ::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}
.pricing-container .pricing-plans .plan {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 7px;
  width: 350px;
  height: 600px;
  overflow-y: scroll;
  position: relative;
}
.pricing-container .pricing-plans .plan .title {
  font-size: 28px;
  margin: 0;
  padding-left: 30px;
  padding-right: 50px;
}
.pricing-container .pricing-plans .plan .price {
  font-size: 30px;
  color: #fe5631;
  margin: 0;
  padding-left: 30px;
  padding-right: 50px;
}
.pricing-container .pricing-plans .plan .features {
  padding-left: 30px;
  padding-right: 50px;
  text-align: left;
}
.pricing-container .pricing-plans .plan .features ul {
  list-style: none;
}
.pricing-container .pricing-plans .plan .features ul li {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pricing-container .pricing-plans .plan .features ul li i {
  font-size: 10px;
  color: #fe5631;
}
.pricing-container .pricing-plans .plan .features ul li p {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.pricing-container .pricing-plans .plan .btn {
  position: sticky;
  bottom: -10px;
  margin: auto;
}
.pricing-container .pricing-plans .plan .btn .pricing-btn {
  width: 250px;
  padding: 12px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 16px;
  border: none;
  background-color: #fe5631;
  transition: 0.5s;
}
.pricing-container .pricing-plans .plan .btn .pricing-btn:hover {
  cursor: pointer;
  background-color: #1dc295;
}
.pricing-container .plan-breakdown-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(81, 80, 80, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}/*# sourceMappingURL=Pricing.css.map */