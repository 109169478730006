.community-link-required-modall {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(164, 164, 164);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 60%;
}
.community-link-required-modall p {
  text-align: center;
  color: black;
}
.community-link-required-modall .header {
  text-align: center;
  color: #1F80E0;
  font-size: 1.2em;
}
.community-link-required-modall a {
  padding: 10px;
  border-radius: 50px;
  background-color: #1F80E0;
  font-weight: 600;
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.community-link-required-modall a:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 700px) {
  .community-link-required-modall {
    width: 95%;
  }
}/*# sourceMappingURL=SignInRequired.css.map */