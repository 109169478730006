/* Marquee.css */
.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #F7F7F7;
    margin-top: 8px;
  }
  
  .marquee-content {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 25s linear infinite, colorChange 3s linear infinite;
    font-size: 1.4em;
    font-weight: 600;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes colorChange {
    0% {
      color: green; /* Starting color */
    }
    25% {
      color: blue;
    }
    50% {
      color: green;
    }
    75% {
      color: blue;
    }
    100% {
      color: green; /* Ending color */
    }
  }
  