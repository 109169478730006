.stepfour-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.stepfour-container-header-div{
    width: 35%;
    text-align: center;
}
.stepfour-container-header{
    font-size: 2em;
    color: #F8F9FA;
}
.stepfour-container-prevbutton{
    margin-top: 10%;
}
.container-flexbox2{
    margin-top: 2%;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    background-color: #F8F9FA;
    margin-bottom: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepfour-propertytype-container{
    margin-top: 2%;
    margin-left: 6%;
    width: 45%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
    color: #686868;
}
.stepfour-servicerequirement-container{
    margin-top: 20px;
    margin-left: 2%;
    width: 45%;
}
.stepfour-startofwork-container{
    margin-top: 2%;
    margin-left: 6%;
    width: 45%;
}
.stepfour-description-container{
    margin-top: 2%;
    margin-left: 6%;
    width: 80%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
}
.descriptionprompt-inputbox{
    width: 80%;
    height: 5em;
    max-width: 40em;
    height: 20vh;
    font-size: 1.2em;
    letter-spacing: 0.5px;
    margin-top: 1%;
    margin-bottom: 2%;
}
.stepfour-button-container{
    margin-top: 2%;
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}
.stepfour-button-prev{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
}
.stepfour-button-next-disable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
    display: inline-block;
}
.stepfour-button-next-enable{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
}
@media only screen and (min-width: 1401px) and (max-width: 1501px) {
.stepfour-container-header-div{
    width: 40%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
    margin-left: 5%;
}
.container-flexbox2{
    width: 55%;
    /* margin-left: 5%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 3%;
    margin-left: 6%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 1301px) and (max-width: 1401px) {
.stepfour-container-header-div{
    width: 40%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
    margin-left: 5%;
}
.container-flexbox2{
    width: 55%;
    /* margin-left: 5%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 3%;
    margin-left: 6%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 1201px) and (max-width: 1301px) {
.stepfour-container-header-div{
    width: 40%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
    margin-left: 5%;
}
.container-flexbox2{
    width: 50%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 3%;
    margin-left: 6%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 1101px) and (max-width: 1201px) {
.stepfour-container-header-div{
    width: 40%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
    margin-left: 5%;
}
.container-flexbox2{
    width: 50%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 3%;
    margin-left: 6%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 1001px) and (max-width: 1101px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 3%;
    margin-left: 6%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 3%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 2%;
}
}
@media only screen and (min-width: 901px) and (max-width: 1001px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
}
.stepfour-container-prevbutton{
    margin-top: 5%;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 5%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 801px) and (max-width: 901px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
}
.stepfour-container-prevbutton{
    margin-top: 5%;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 5%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-container-prevbutton {
    margin-top: 5%;
}
}
@media only screen and (min-width: 701px) and (max-width: 801px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
}
.stepfour-container-prevbutton{
    margin-top: 5%;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 5%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 5%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 20em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
}
@media only screen and (min-width: 601px) and (max-width: 701px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 5%;
}
.stepfour-container-header{
    font-size: 2.5em;
}
.stepfour-container-prevbutton{
    margin-top: 5%;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 18em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
}
@media only screen and (min-width: 501px) and (max-width: 601px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 8%;
}
.stepfour-container-header{
    font-size: 2.4em;
}
.stepfour-container-prevbutton{
    margin-top: 5%;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 18em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
}
@media only screen and (min-width: 401px) and (max-width: 501px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 15%;
}
.stepfour-container-header{
    font-size: 2.3em;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 15em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 8%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
}
@media only screen and (min-width: 301px) and (max-width: 401px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 15%;
}
.stepfour-container-header{
    font-size: 2.2em;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 5%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-button-container{
    margin-top: 8%;
    margin-bottom: 4%;
}
}
@media only screen and (min-width: 201px) and (max-width: 301px) {
.stepfour-container-header-div{
    width: 100%;
    margin-top: 15%;
}
.stepfour-container-header{
    font-size: 2.2em;
}
.container-flexbox2{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
}
.stepfour-propertytype-container{
    width: 100%;
    margin-top: 20%;
}
.stepfour-servicerequirement-container{
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
}
.stepfour-propertytype-container-subheader{
    font-size: 1.4em;
}
.stepfour-startofwork-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-description-container{
    width: 100%;
    margin-top: 10%;
}
.stepfour-propertytype-container-subheader{
    margin-left: 8%;
}
.propertytype-dropdown{
    margin-top: 1%;
    width: 90%;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-left: 5%;
}
.descriptionprompt-inputbox{
    margin-left: 8%;
}
.stepfour-button-container{
    margin-top: 10%;
    margin-bottom: 5%;
}
}