* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.invoice-container {
  padding: 20px;
  display: none;
}
.invoice-container p {
  font-size: 17px;
}
.invoice-container .upper {
  font-size: 17px;
}
.invoice-container .upper b {
  color: #fe5631;
}
.invoice-container .upper:nth-child(2) {
  margin-top: 5px;
}
.invoice-container table {
  padding: 10px;
  border-collapse: collapse;
}
.invoice-container table tr {
  width: 100%;
  border-collapse: collapse;
}
.invoice-container table tr th {
  font-size: 16.3px;
  padding: 5px;
  border: 1px solid lightgray;
}
.invoice-container table tr td {
  font-size: 16.3px;
  padding: 5px;
  border: 1px solid lightgray;
  text-align: center;
}
.invoice-container table .transaction-data td {
  font-size: 14px;
  font-weight: 500;
}
.invoice-container table .transaction-data td span {
  color: #1dc295;
  transition: 0.4s;
}
.invoice-container table .transaction-data td span:hover {
  cursor: pointer;
  color: #fe5631;
}
.invoice-container table td {
  min-width: 150px;
}
.invoice-container img {
  width: 150px;
}/*# sourceMappingURL=PlansInvoice.css.map */