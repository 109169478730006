* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  height: 6px;
  background-color: lightgray;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  background-color: rgb(99, 97, 97);
}

.cart-page-table-container {
  overflow-x: scroll;
  width: 93%;
  margin: auto;
}
.cart-page-table-container table {
  border: 1px solid lightgray;
  border-collapse: collapse;
  margin: auto;
  width: 900px;
}
.cart-page-table-container table tr {
  border: 1px solid lightgray;
}
.cart-page-table-container table tr th,
.cart-page-table-container table tr td {
  padding: 10px;
  border: 1px solid lightgray;
  min-width: 100px;
  text-align: center;
}
.cart-page-table-container table tr .long-text {
  min-width: 200px;
}
.cart-page-table-container table tr .delete {
  margin: auto;
  width: 80px;
  padding: 5px;
  border: none;
  color: white;
  font-weight: 600;
  background-color: rgb(208, 1, 1);
}
.cart-page-table-container table tr .delete:hover {
  cursor: pointer;
}

@media only screen and (min-width: 1501px) and (max-width: 2501px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 5px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1501px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1101px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1001px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 801px) and (max-width: 901px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 701px) and (max-width: 801px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 601px) and (max-width: 701px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 501px) and (max-width: 601px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 1.1em;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 401px) and (max-width: 501px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 301px) and (max-width: 401px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 201px) and (max-width: 301px) {
  .cart-page-table-container {
    overflow-x: scroll;
    width: 90%;
    /* margin: auto; */
  }
  .cart-page-table-container table {
    border: 1px solid lightgray;
    border-collapse: collapse;
    /* margin: auto; */
    /* width: 900px; */
  }
  .cart-page-table-container table tr {
    border: 1px solid lightgray;
  }
  .cart-page-table-container table tr th,
  .cart-page-table-container table tr td {
    padding: 3px;
    border: 1px solid lightgray;
    text-align: center;
  }
  .cart-page-table-container table tr .long-text {
    min-width: 200px;
  }
  .cart-page-table-container table tr .delete {
    margin: auto;
    width: 80px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgb(208, 1, 1);
  }
  .cart-page-table-container table tr .delete:hover {
    cursor: pointer;
  }
}