.stepfive-container{
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.stepfive-container-flexbox1{
    width: 35%;
    text-align: center;
}
.stepfive-container-flexbox2{
    width: 60%;
    margin-top: 2%;
    background-color: #F8F9FA;
    margin-bottom: 2%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.stepfive-container-prevbutton{
    margin-top: 10%;
}
.stepfive-container-header{
    font-size: 2.5em;
    color: #F8F9FA;
}
.stepfive-containerbox{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 6%;
}
/* .stepfive-container{
} */
.stepfive-container-div{
    margin-top: 20px;
    flex: 50%;
}
.stepfive-container-label{
    font-size: 1.4em;
    margin-left: 2%;
    color: #686868;
}
.stepfive-container-submit-div{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    margin-top: 2%;
    margin-bottom: 2%;
}
.stepfive-container-declaration-div{
    width: 80%;
    margin-left: 6%;
}
.declaration-label{
    font-size: 1.4em;
}
.stepfive-button-container{
    width: 100%;
    text-align: center;
    margin-top: 2%;
}
.stepfive-container-input{
    width: 350px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 5px;
    font-size: 1.1em;
    letter-spacing: 0.5px;
    margin-top: 1%;
    /* color: grey; */
    /* background-color: #686868; */
}
.stepfive-prev-button{
    width: 6em;
    height: 2em;
    border-radius: 75px;
    background-color: white;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.1px;
    border: 1px solid white;
    display: inline-block;
    margin-right: 20px;
    box-shadow: 10px 4px 40px 5px #0ff;
}

.stepfive-dropdown-city{
    margin-top: 1%;
    margin-left: 1%;
    width: 28vw;
    height: 5vh;
    font-size: 1.2em;
    border-radius: 5px;
}

input::placeholder{
    color: darkgrey;
    letter-spacing: 0.5px;
}
.stepfive-containerbox{
    display: flex;
}
.stepfive-dropdown-city{
    width: 350px;
    border: 1px solid black;
}
@media only screen and (min-width: 1201px) and (max-width: 1301px) {
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-dropdown-city{
        width: 350px;
        border: 1px solid black;
    }
}
@media only screen and (min-width: 601px) and (max-width: 701px) {
    .stepfive-container-flexbox1{
        width: 100%;
    }
    .stepfive-container-prevbutton{
        margin-top: 5%;
    }
    .stepfive-container-flexbox2{
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .stepfive-container-div{
        width: 100%;
        margin-left: 2%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-container-input{
        width: 80%;
    }
    .stepfive-container-declaration-div{
        margin-left: 10%;
        margin-top: 3%;
    }
    .stepfive-dropdown-city{
        width: 80%;
        border: 1px solid black;
    }
    .stepfive-button-container{
        margin-top: 4%;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 501px) and (max-width: 601px) {
    .stepfive-container-flexbox1{
        width: 100%;
    }
    .stepfive-container-prevbutton{
        margin-top: 5%;
    }
    .stepfive-container-flexbox2{
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .stepfive-container-div{
        width: 100%;
        margin-left: 5%;
    }
    .stepfive-container-declaration-div{
        margin-left: 12%;
        margin-top: 3%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-dropdown-city{
        width: 350px;
        border: 1px solid black;
    }
    .stepfive-button-container{
        margin-top: 6%;
        margin-bottom: 3%;
    }
}
@media only screen and (min-width: 401px) and (max-width: 501px) {
    .stepfive-container-flexbox1{
        width: 100%;
    }
    .stepfive-container-prevbutton{
        margin-top: 5%;
    }
    .stepfive-container-flexbox2{
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .stepfive-containerbox{
        margin-top: 5%;
        margin-left: 8%;
    }
    .stepfive-container-input{
        width: 90%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-dropdown-city{
        width: 90%;
        border: 1px solid black;
    }
    .stepfive-button-container{
        margin-top: 6%;
        margin-bottom: 5%;
    }
}
@media only screen and (min-width: 301px) and (max-width: 401px) {
    .stepfive-container-flexbox1{
        width: 100%;
    }
    .stepfive-container-prevbutton{
        margin-top: 5%;
    }
    .stepfive-container-flexbox2{
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .stepfive-containerbox{
        margin-top: 5%;
        margin-left: 15%;
    }
    .stepfive-container-input{
        width: 90%
    }
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-dropdown-city{
        width: 90%;
        border: 1px solid black;
    }
    .stepfive-button-container{
        margin-top: 10%;
        margin-bottom: 5%;
    }
}
@media only screen and (min-width: 201px) and (max-width: 301px) {
    .stepfive-container-flexbox1{
        width: 100%;
    }
    .stepfive-container-prevbutton{
        margin-top: 5%;
    }
    .stepfive-container-flexbox2{
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .stepfive-containerbox{
        margin-top: 5%;
        margin-left: 10%;
    }
    .stepfive-container-input{
        width: 90%;
    }
    .stepfive-containerbox{
        display: flex;
        flex-direction: column;
    }
    .stepfive-dropdown-city{
        width: 90%;
        border: 1px solid black;
    }
    .stepfive-button-container{
        margin-top: 10%;
        margin-bottom: 18%;
    }
}