.form-partone-container {
    background-image: linear-gradient(to right, #3931AF , #00C6FF);
    display: flex;
    flex-wrap: wrap;
    height: 55vh;
}
.form-partone-container-header-div{
    width: 40%;
    margin-top: 8%;
    margin-left: 120px;
}
.form-partone-container-whole-div{
    display: flex;
    flex-wrap: wrap;
    background-color: #F8F9FA;
    width: 50%;
    margin-top: 4%;
    height: 40vh;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.form-partone-container-header{
    font-size: 2.5em;
    font-weight: 500;
    color: white;
}
.form-partone-container-subheader{
    font-size: 1.2em;
    font-weight: 400;
    margin-top: 10px;
    inline-size: 80%;
    color: white;
    margin-left: 6px;
}
.serviceType-header {
    font-size: 1.4em;
    font-weight: 500;
    color: #686868;
}
.servicetype-dropdown {
    text-align: center;
    margin-left: 10%;
    margin-top: 10%;
    width: 80%;
    height: 15%;
}
.ServiceType-select {
    width: 25em;
    height: 2em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
    margin-top: 4%;
}
.serviceType-button-container {
    width: 100%;
    margin-right: 25%;
}
.serviceType-button-enabled {
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: white;
    background-color: #1F80E0;
    border: 1px solid #1F80E0;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    float: right;
}
.serviceType-button-disable {
    float: right;
    width: 6em;
    height: 2em;
    border-radius: 75px;
    color: #1F80E0;
    background-color: white;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 1px solid #1F80E0;
}

@media only screen and (min-width: 1200px) and (max-width: 1301px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 40%;
        margin-top: 8%;
        /* margin-left: 120px; */
        /* margin-right: 10%; */
        text-align: center;
    }
    .form-partone-container-subheader{
        /* margin-left: 10%; */
        font-size: 1.2em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 50%;
        margin-top: 4%;
        /* margin-left: 2%; */
        margin-bottom: 2%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1201px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        /* margin-right: 10%; */
        text-align: center;
    }
    .form-partone-container-subheader{
        margin-left: 10%;
        font-size: 1.2em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 1000px) and (max-width: 1101px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        /* margin-right: 10%; */
        text-align: center;
    }
    .form-partone-container-subheader{
        margin-left: 10%;
        font-size: 1.2em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1001px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        margin-right: 10%;
        text-align: center;
    }
    .form-partone-container-header{
        margin-left: 5%;
    }
    .form-partone-container-subheader{
        margin-left: 13%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 800px) and (max-width: 901px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        /* margin-right: 10%; */
        text-align: center;
    }
    .form-partone-container-header{
        margin-right: 10%;
    }
    .form-partone-container-subheader{
        margin-left: 5%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 700px) and (max-width: 801px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        /* margin-right: 10%; */
        text-align: center;
    }
    .form-partone-container-header{
        margin-right: 15%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
}
@media only screen and (min-width: 600px) and (max-width: 701px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        margin-right: 8%;
        text-align: center;
    }
    .form-partone-container-header{
        font-size: 2.2em;
        font-weight: 500;
        color: white;
    }
    .form-partone-container-subheader{
        margin-left: 5%;
        font-size: 1.1em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.3em;
        font-weight: 500;
        color: #686868;
    }
    .ServiceType-select {
        width: 20em;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 4%;
    }
}
@media only screen and (min-width: 500px) and (max-width: 601px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        margin-right: 8%;
        text-align: center;
    }
    .form-partone-container-header{
        font-size: 2.2em;
        font-weight: 500;
        color: white;
    }
    .form-partone-container-subheader{
        margin-left: 5%;
        font-size: 1.1em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 76%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.3em;
        font-weight: 500;
        color: #686868;
    }
    .ServiceType-select {
        width: 18em;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 4%;
    }
}
@media only screen and (min-width: 400px) and (max-width: 501px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        /* margin-left: 120px; */
        margin-right: 8%;
        text-align: center;
    }
    .form-partone-container-header{
        font-size: 2.2em;
        font-weight: 500;
        color: white;
    }
    .form-partone-container-subheader{
        margin-left: 5%;
        font-size: 1.1em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 80%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.2em;
        font-weight: 500;
        color: #686868;
    }
    .ServiceType-select {
        width: 15em;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 4%;
    }
}
/* @media only screen and (min-width: 300px) and (max-width: 401px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 8%;
        margin-right: 8%;
        text-align: center;
    }
    .form-partone-container-header{
        font-size: 2em;
        font-weight: 500;
        color: white;
    }
    .form-partone-container-subheader{
        margin-left: 5%;
        font-size: 1em;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 80%;
        margin-top: 4%;
        margin-left: 13.5%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.2em;
        font-weight: 500;
        color: #686868;
    }
    .ServiceType-select {
        width: 13em;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 4%;
    }
} */
@media only screen and (min-width: 351px) and (max-width: 401px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 10%;
    }
    .form-partone-container-header{
        font-size: 2.4em;
        font-weight: 500;
        color: white;
        margin-left: -15%;
        width: 100%;
    }
    .form-partone-container-subheader{
        font-size: 1.2em;
        margin-left: -11%;
        width: 100%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 90%;
        margin-top: 4%;
        margin-left: 2%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.3em;
        font-weight: 500;
        color: #686868;
        margin-top: 10%;
    }
    .ServiceType-select {
        width: 90%;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 10%;
    }
    .serviceType-button-container{
        margin-top: 35%;
    }
    .serviceType-button-enabled {
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: white;
        background-color: #1F80E0;
        border: 1px solid #1F80E0;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        float: right;
    }
    .serviceType-button-disable {
        float: right;
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: #1F80E0;
        background-color: white;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        border: 1px solid #1F80E0;
    }
}
@media only screen and (min-width: 300px) and (max-width: 351px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 10%;
    }
    .form-partone-container-header{
        font-size: 2.3em;
        font-weight: 500;
        color: white;
        margin-left: -24%;
        width: 100%;
    }
    .form-partone-container-subheader{
        font-size: 1.1em;
        margin-left: -21%;
        width: 100%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 90%;
        margin-top: 4%;
        margin-left: 2%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.3em;
        font-weight: 500;
        color: #686868;
        margin-top: 10%;
    }
    .ServiceType-select {
        width: 90%;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 10%;
    }
    .serviceType-button-container{
        margin-top: 35%;
    }
    .serviceType-button-enabled {
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: white;
        background-color: #1F80E0;
        border: 1px solid #1F80E0;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        float: right;
    }
    .serviceType-button-disable {
        float: right;
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: #1F80E0;
        background-color: white;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        border: 1px solid #1F80E0;
    }
}
@media only screen and (min-width: 200px) and (max-width: 301px) {
    .form-partone-container {
        background-image: linear-gradient(to right, #3931AF , #00C6FF);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }
    .form-partone-container-header-div{
        width: 100%;
        margin-top: 10%;
    }
    .form-partone-container-header{
        font-size: 2.2em;
        font-weight: 500;
        color: white;
        margin-left: -25%;
        width: 100%;
    }
    .form-partone-container-subheader{
        font-size: 1.1em;
        margin-left: -22%;
        width: 100%;
    }
    .form-partone-container-whole-div{
        display: flex;
        flex-wrap: wrap;
        background-color: #F8F9FA;
        width: 90%;
        margin-top: 4%;
        margin-left: 2%;
        height: 40vh;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2%;
    }
    .serviceType-header {
        font-size: 1.3em;
        font-weight: 500;
        color: #686868;
        margin-top: 10%;
    }
    .ServiceType-select {
        width: 90%;
        height: 2em;
        font-size: 1.2em;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 10px;
        margin-top: 10%;
    }
    .serviceType-button-container{
        margin-top: 35%;
    }
    .serviceType-button-enabled {
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: white;
        background-color: #1F80E0;
        border: 1px solid #1F80E0;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        float: right;
    }
    .serviceType-button-disable {
        float: right;
        width: 6em;
        height: 2em;
        border-radius: 75px;
        color: #1F80E0;
        background-color: white;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.5px;
        border: 1px solid #1F80E0;
    }
}