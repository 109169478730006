body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
p {
  margin: 0;
}

.plan-breakdown-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(125, 123, 123, 0.5);
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.plan-breakdown-container .title {
  text-align: center;
}
.plan-breakdown-container .plan-breakdown {
  margin: 0;
  padding: 0;
  width: 800px;
  height: 80%;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 20px rgb(101, 100, 100);
  overflow-x: scroll;
  position: relative;
}
.plan-breakdown-container .plan-breakdown #close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
}
.plan-breakdown-container .plan-breakdown #close:hover {
  cursor: pointer;
}
.plan-breakdown-container .plan-breakdown .amounttobepaid {
  font-size: 17px;
  margin-top: 10px;
  text-align: center;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .plan-title-and-price {
  display: flex;
  justify-content: left;
  -moz-column-gap: 30px;
       column-gap: 30px;
  align-items: center;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .title {
  font-size: 35px;
  font-weight: bolder;
  color: #fe5631;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .pricing {
  color: #1dc295;
  font-size: 25px;
  font-weight: bolder;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-title {
  color: black;
  font-size: 26px;
  font-weight: 600;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .features {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  row-gap: 20px;
  background-color: rgb(243, 240, 240);
  padding: 20px;
  border-radius: 5px;
  width: 45%;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .features .feature {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  color: rgb(72, 71, 71);
  font-weight: 600;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .features .feature #dot {
  color: #1dc295;
  font-size: 12px;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes h3 {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes .fee {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes .fee .title,
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes .fee .amount {
  font-size: 15px;
  font-weight: 600;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes .fee .title {
  color: black;
  text-align: left;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes .fee .amount {
  color: rgb(93, 92, 92);
  font-weight: normal;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .proceed {
  margin-left: 30px;
  /* background-color: #fe5631; */
  padding: 10px;
  border-radius: 3px;
  border: none;
  outline: none;
  font-weight: 600;
  color: white;
  font-size: 16px;
  letter-spacing: 1px;
  width: 200px;
  margin-top: 10px;
}
.plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .proceed:hover {
  cursor: pointer;
}
.plan-breakdown-container .plan-breakdown table {
  border-collapse: collapse;
  border: 1px solid lightgray;
}
.plan-breakdown-container .plan-breakdown table tr {
  border: 1px solid lightgray;
}
.plan-breakdown-container .plan-breakdown table tr th,
.plan-breakdown-container .plan-breakdown table tr td {
  padding: 10px;
  min-width: 100px;
  border: 1px solid lightgray;
}
.text-input {
  padding: 6px;
  border: 1px solid lightgray;
  border-radius: 3px;
  outline: none;
  transition: 0.5s;
  width: 200px;
}
@media only screen and (max-width: 900px) {
  .plan-breakdown-container .plan-breakdown {
    width: 100%;
    top: 0;
    left: 0;
    transform: none;
  }
}
@media only screen and (max-width: 600px) {
  .plan-breakdown-container .plan-breakdown {
    height: 100%;
    overflow-y: scroll;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container {
    flex-direction: column;
    row-gap: 10px;
    justify-content: left;
    align-items: flex-start;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .features {
    width: 100%;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .fee-and-taxes {
    min-width: 100%;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .proceed {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 420px) {
  .plan-breakdown-container .plan-breakdown {
    height: 100%;
    overflow-y: scroll;
  }
  .plan-breakdown-container .plan-breakdown #close {
    position: fixed;
    top: 10;
    right: 10;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .plan-title-and-price {
    flex-direction: column;
    align-items: flex-start;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details {
    position: relative;
    padding-bottom: 50px;
  }
  .plan-breakdown-container .plan-breakdown .plan-breakdown-details .features-container .billing-details .proceed {
    position: fixed;
    bottom: 10px;
  }
}/*# sourceMappingURL=PlanBreakDown.css.map */