.divmain-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.divmain-container .div1 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}
.divmain-container .div2 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}
.divmain-container .div3 {
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
}