* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.MyPurchasedLeads-container .mypurchasedleads-transaction-table {
  width: 100%;
  margin-top: 20px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
  padding: 10px;
  border-collapse: collapse;
  overflow-x: scroll;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table tr {
  width: 100%;
  border-collapse: collapse;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table tr th {
  font-size: 16.3px;
  padding: 5px;
  border: 1px solid lightgray;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table tr td {
  font-size: 16.3px;
  padding: 5px;
  border: 1px solid lightgray;
  text-align: center;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table .transaction-data td {
  font-size: 14px;
  font-weight: 500;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table .transaction-data td span {
  color: #1dc295;
  transition: 0.4s;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table table .transaction-data td span:hover {
  cursor: pointer;
  color: #fe5631;
}
.MyPurchasedLeads-container .mypurchasedleads-transaction-table .converted p {
  color: white;
  background-color: rgb(7, 192, 7);
  width: 100px;
  margin: auto;
}
.email-buttom-details{
  background-color: #fe5631;
  color: white;
  padding: 2px;
  width: 80px;
  font-size: 1.1em;
  letter-spacing: 1px;
  border-radius: 5px;
  border-color: #fe5631;
}

@media only screen and (min-width: 1801px) and (max-width: 1901px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1750px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1750px;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1801px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1650px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1650px;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1701px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1550px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1550px;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1601px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1450px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1450px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1501px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1350px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1350px;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1401px) {
  .MyPurchasedLeads-container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1250px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1250px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1301px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1150px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1150px;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1201px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 1050px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1101px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 950px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1001px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 850px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 901px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 750px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 701px) and (max-width: 801px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 650px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 701px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 550px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 601px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 450px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 501px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 350px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 301px) and (max-width: 401px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 250px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 201px) and (max-width: 301px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 250px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}

@media only screen and (min-width: 101px) and (max-width: 201px) {
  .MyPurchasedLeads-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar {
    visibility: visible;
    height: 15px;
    background-color: lightgray;
  }
  .MyPurchasedLeads-container ::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: rgb(62, 61, 61);
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table {
    width: 150px;
    overflow-x: scroll;
    align-items: flex-start;
  }
  .MyPurchasedLeads-container .mypurchasedleads-transaction-table table {
    width: 1000px;
  }
}
.replacement-btn {
    color: white;
    border: none;
    outline: none;
    padding: 7px;
    border-radius: 3px;
    background: #1dc295;
    font-weight: 500;
    width: 80px;
    margin-top: 10px;
    background-color: red;
  }