.mystats-header{
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 2em;
}
.mystats-div1{
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.mystats-div1-ptag{
    width: 100%;
    font-size: 1.2em;
    font-weight: 600;
}
.mystats-replacement-ptag{
    width: 100%;
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
}

.mystats-div2-table{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    overflow-x: auto;
    text-align: center;
}
.mystats-replacement-table{
    margin-top: 10px;
    margin-left: 10%;
    overflow-x: auto;
    text-align: center;
}
.table1{
    border: 1px solid black;
    border-collapse: collapse;
    th, td {
        text-align: left;
        padding: 10px;
        border: 1px solid black;
        text-align: center;
      }
      th{
        font-size: 1.1em;
      }
}
.ptag-note{
    font-size: 1.5em;
}
.pleasenote-listitem{
    font-size: 1.1em;
}
.mystats-div2-ptag{
    width: 100%;
    margin-top: 10px;
    text-align: center; 
    font-size: 1.2em;  
    font-weight: 600;
}
.mystats-planDescription{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.mystats-planDescription-div1{
    width: 100%;
    float: left;
    text-align: center;
    border: 2px solid lightgray;
}
.mystats-planDescription-div1-header{
    font-weight: 500;
    font-size: 1.4em;
    margin-top: 5px;
}
.mystats-div1-ptag-description{
    margin-top: 1%;
    font-size: 1.2em;
}
.mystats-planDescription-div2{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 20px;
    border: 2px solid lightgray;
}
.mystats-planDescription-div2-header{
    font-weight: 500;
    font-size: 1.4em;
    margin-top: 20px;
}
.mystats-link{
    margin-top: 5px;
    font-size: 1.1em;
}
.mystats-sharing-button1{
    font-size: 1.4em;
    padding: 10px;
    color: white;
    background-color: #fe5631;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 600;
    width: 90%;
}
.mystats-sharing-button2{
    font-size: 1.4em;
    font-weight: 600;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: #1DC295;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
}
@media only screen and (min-width: 801px) and (max-width: 901px) {
    .mystats-div2-table{
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.4em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 90%;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.4em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 90%;
        padding: 5px;
    }
}

@media only screen and (min-width: 701px) and (max-width: 801px) {
    .mystats-div2-table{
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.4em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 90%;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.4em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 90%;
        padding: 5px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 701px) {
    .mystats-div2-table{
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.3em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 90%;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.3em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 90%;
        padding: 5px;
    }
}

@media only screen and (min-width: 501px) and (max-width: 601px) {
    .mystats-div2-table{
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.2em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 90%;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.2em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 90%;
        padding: 5px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 501px) {
    .mystats-div2-table{
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.2em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 80%;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.2em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 80%;
        padding: 5px;
    }
}

@media only screen and (min-width: 301px) and (max-width: 401px) {
    .mystats-div2-table{
        width: 400px;
        margin-left: 20px;
        margin-top: 10px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 10%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1.1em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 160px;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1.1em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 160px;
        padding: 5px;
    }
}

@media only screen and (min-width: 201px) and (max-width: 301px) {
    .mystats-div2-table{
        width: 300px;
        margin-left: 20px;
        margin-top: 10px;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-replacement-table{
        margin-top: 10px;
        margin-left: 1%;
        overflow-x: auto;
        text-align: center;
    }
    .mystats-sharing-button1{
        font-size: 1em;
        padding: 10px;
        color: white;
        background-color: #fe5631;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        width: 120px;
        padding: 5px;
    }
    .mystats-sharing-button2{
        font-size: 1em;
        font-weight: 600;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 5px;
        background-color: #1DC295;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 120px;
        padding: 5px;
    }
}