* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
}

.refund-page-container .title,
.refund-page-container .sub-title {
  margin-top: 50px;
  text-align: center;
  font-size: 25px;
}
.refund-page-container .container {
  width: 95%;
  margin: auto;
  margin-top: 20px;
}
.refund-page-container .container .sub-title {
  font-size: 25px;
  text-align: center;
}
.refund-page-container .container .feature-box {
    display: flex;
    justify-content: center;
}
.refund-page-container .container .feature-box .box1 {
    width: 40%;
}
.refund-page-container .container .feature-box .box2 {
    width: 40%;
}
.refund-page-container .container .feature-box .box1 .list .feature {
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
}
.refund-page-container .container .feature-box .box2 .list .feature {
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
}
.refund-page-container .container .list {
  padding-left: 30px;
  padding-right: 20px;
  margin-top: 20px;
}
.refund-page-container .container .list .header {
  font-size: 17px;
}
.refund-page-container .container .list .header .sub-list {
  margin-left: 30px;
}
.refund-page-container .container .list .header .sub-list .sub-list-2 {
  margin-left: 30px;
  list-style: disc;
}/*# sourceMappingURL=RefundReplacement.css.map */