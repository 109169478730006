.membershipDescription-container{
    text-align: center;
}
.p-tag{
    color: #1F80E0;
    font-weight: 600;
    font-size: 1.2em;
}
.buttons-div-container1{
    text-align: center;
    margin-top: 20px;
}
.div-button{
    font-size: 1.8em;
    background-color: #1F80E0;
    color: white;
    font-weight: 600;
    padding: 15px;
    border-radius: 5px;
    border: none;
}

@media only screen and (min-width: 601px) and (max-width: 801px) {
    .p-tag{
        color: #1F80E0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .buttons-div-container1{
        text-align: center;
        margin-top: 20px;
    }
    .div-button{
        width: 90%;
        font-size: 1.4em;
        background-color: #1F80E0;
        color: white;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }
}

@media only screen and (min-width: 501px) and (max-width: 601px) {
    .p-tag{
        color: #1F80E0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .buttons-div-container1{
        text-align: center;
        margin-top: 20px;
    }
    .div-button{
        width: 90%;
        font-size: 1.3em;
        background-color: #1F80E0;
        color: white;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }
}

@media only screen and (min-width: 401px) and (max-width: 501px) {
    .p-tag{
        color: #1F80E0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .buttons-div-container1{
        text-align: center;
        margin-top: 20px;
    }
    .div-button{
        width: 90%;
        font-size: 1.2em;
        background-color: #1F80E0;
        color: white;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }
}

@media only screen and (min-width: 301px) and (max-width: 401px) {
    .p-tag{
        color: #1F80E0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .buttons-div-container1{
        text-align: center;
        margin-top: 20px;
    }
    .div-button{
        width: 80%;
        font-size: 1.2em;
        background-color: #1F80E0;
        color: white;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }
}

@media only screen and (min-width: 200px) and (max-width: 301px) {
    .p-tag{
        color: #1F80E0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .buttons-div-container1{
        text-align: center;
        margin-top: 20px;
    }
    .div-button{
        width: 80%;
        font-size: 1.2em;
        background-color: #1F80E0;
        color: white;
        padding: 15px;
        border-radius: 5px;
        border: none;
    }
}