* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Property-req-container {
  background-color: white;
}
.Property-req-container p {
  margin: 0;
}
.Property-req-container .sign-in-required-modall {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(164, 164, 164);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Property-req-container .sign-in-required-modall .close-icon {
  color: #fe5631;
}
.Property-req-container .sign-in-required-modall h1 {
  color: #fe5631;
  text-align: center;
}
.Property-req-container .sign-in-required-modall a {
  padding: 10px;
  border-radius: 50px;
  background-color: #1dc295;
  font-weight: 600;
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.Property-req-container .sign-in-required-modall a:hover {
  opacity: 0.6;
}
.Property-req-container .properties-featured-properties-container .titleanddesc {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Property-req-container .properties-featured-properties-container .titleanddesc .title {
  font-size: 35px;
}
.Property-req-container .properties-featured-properties-container .titleanddesc .desc {
  font-size: 17px;
  color: rgb(90, 90, 90);
  width: 800px;
  text-align: center;
}
.Property-req-container .properties-featured-properties-container .no-product-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}
.Property-req-container .properties-featured-properties-container .no-product-found p {
  margin: 0;
  color: rgb(123, 122, 122);
  font-size: 25px;
  font-weight: 500;
  font-variant: small-caps;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-container {
  overflow-y: hidden;
  margin-top: 50px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property {
  border-radius: 5px;
  width: 420px;
  height: auto;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper {
  position: relative;
  padding: 10px;
  height: 350px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper img {
  box-shadow: none;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper .tag {
  background-color: #1dc295;
  border-radius: 5px;
  font-weight: 500;
  width: 120px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: white;
  padding: 5px;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  transition: 0.5s;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper #interior-tag {
  background-color: #0000ff;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper #construction-tag {
  width: 150px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper .price {
  font-weight: 500;
  width: 120px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fe5631;
  letter-spacing: 0.5px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  box-shadow: 0 0 2px rgb(209, 207, 207);
  transition: 0.5s;
  border: none;
  border-radius: 4px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper .upper-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0 0 10px white;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper .leadswithoutauth-sold-out {
  position: absolute;
  left: 20px;
  top: 250px;
  width: 100px;
  height: 100px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .upper .leadswithoutauth-nqa-replacement {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 125px;
  height: 125px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
  padding-bottom: 15px;
  width: 100%;
  height: 320px;
  border-bottom: 1px solid lightgray;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .address {
  color: #fe5631;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-content: left;
  align-items: baseline;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .property-title {
  font-size: 22px;
  font-weight: bolder;
  width: 100%;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .apartmentandsize {
  width: 100%;
  font-weight: 500;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .other-details {
  display: flex;
  -moz-column-gap: 7px;
       column-gap: 7px;
  flex-wrap: wrap;
  width: 400px;
  align-items: center;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .other-details .dtl {
  width: 120px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .other-details .dtl p {
  color: rgb(107, 106, 106);
  font-size: 13.5px;
  font-weight: 500;
  text-align: center;
  width: 120px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .middle .other-details .border {
  background-color: lightgray;
  width: 1px;
  height: 40px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .bottom .rating {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .bottom .rating .stars {
  color: rgb(248, 180, 42);
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .bottom .rating .stars span {
  font-size: 22px;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property .bottom .rating .rating-name {
  font-size: 17px;
  color: rgb(107, 105, 105);
  position: relative;
  bottom: 2px;
  font-weight: 500;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property:hover .tag,
.Property-req-container .properties-featured-properties-container .Featured-properties-property:hover #interior-tag {
  background-color: #fe5631;
}
.Property-req-container .properties-featured-properties-container .Featured-properties-property:hover .price {
  background-color: #fe5631;
  color: white;
}

@media only screen and (max-width: 1345px) {
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .upper img {
    min-width: 350px;
  }
}
@media only screen and (max-width: 1200px) {
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .featured-properties-property .upper img {
    width: 400px;
  }
}
@media only screen and (max-width: 900px) {
  .Property-req-container .properties-featured-properties-container .titleanddesc .desc {
    width: 500px;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .featured-properties-property .upper img {
    width: 320px;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .featured-properties-property .bottom {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    row-gap: 10px;
  }
}
@media only screen and (max-width: 750px) {
  .sign-in-required-modall {
    width: 95%;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property {
    width: 100%;
    height: 785px;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .upper {
    width: 100%;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .upper img {
    min-width: 95%;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .middle .address {
    flex-direction: column;
    align-items: flex-start;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .middle .address .addrss {
    width: 100%;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .middle .address .title {
    width: 100%;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .middle .other-details {
    width: 95%;
    row-gap: 10px;
  }
  .Property-req-container .properties-featured-properties-container .Featured-properties-container .Ffeatured-properties .Featured-properties-property .bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}/*# sourceMappingURL=Property.css.map */